import React from "react"
// import styled from "@emotion/styled"

// const H4 = styled.h4`
//   font-family: "komika_title-regular", sans-serif;
//   font-weight: 400;
//   font-style: normal;
//   margin: 5px 0 20px 0;
// `

const QuartenaryHeading = ({children, style = {}}) => (
    <h4 style={style}>{children}</h4>
)

export default QuartenaryHeading