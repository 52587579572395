import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Bio from "../components/Bio"
import PageHeading from '../components/PageHeading'
import SubHeading from '../components/SubHeading'
import TertiaryHeading from '../components/TertiaryHeading'
import QuartenaryHeading from '../components/QuartenaryHeading'
import LinkContainer from '../components/LinkContainer'

const AboutPage = () => (
  <StaticQuery
    query={graphql`
      query {
        headerImg: file(relativePath: { eq: "images/handy_pro.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 980) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ogImg: file(relativePath: { eq: "images/handy_pro-og.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Layout>
          <SEO
            title="Origin Story"
            keywords={[
              "2nd Career Dev",
              "second career developer",
              "rutgers coding bootcamp",
              "freecodecamp",
              "Harvard CS50",
              "javascript",
              "node.js",
              "MERN Stack",
              "CBN",
              "Digital Media",
              "Creativ, LLC",
              "JavaScript Tutor",
              "Trilogy Education Services"
            ]}
            image={{
              src: data.ogImg.childImageSharp.fluid.src,
              height: "1200",
              width: "630",
              alt: "Wesley L. Handy, Full Stack Web Developer, at your service"
            }}
            description="As a 2nd Career Developer, Wesley's journey from Academia to the tech industry has positioned him to make a strong impact, utilizing his broad experience and knowledge base toward your accomplishing your vision."
          />
          <Image
            fluid={data.headerImg.childImageSharp.fluid}
            alt="Wesley L. Handy standing in one of his favorite places in New Jersey, Weehawken overlooking the NYC skyline."
          />
          <PageHeading style={{margin: "30px 0 0 0"}}>Wesley L. Handy</PageHeading>
          <SubHeading style={{margin: "5px 0 30px 0"}}>Full Stack Web Developer</SubHeading>
          <TertiaryHeading>Origins&mdash;Academic to 2nd Career Dev: Birth&ndash;Jul 2016</TertiaryHeading>
          <QuartenaryHeading>Arch-Nemesis: Lack of Marketable Skills</QuartenaryHeading>
          <p>In Summer of 2016 I was at my wits end. I had an earned doctorate, I was teaching adjunctively at three different schools with a load greater than a full-time professor, I had seven years experience in academic administration, and I couldn&rsquo;t find full-time employment as an academic nor an academic administrator in a geographic area saturated with schools and universities. I was at a cross-roads&mdash;either double-down on Academia, go for a second PhD at a prestigious European institution, go into loads of debt, and perhaps still be where I started, or switch careers into something I would love and was good at doing.</p>
          <p>I chose the latter.</p>
          <p>I took two years of computer science courses in High School, many moons ago, and my first major in college, which due to a series of life situations was not my final major, was computer engineering. I had built simple programs in Basic, Pascal, and Fortran. I had played around with AutoCAD. But then dropped the subject altogether to get four degrees in the humanites&mdash;in philosophy, bible, intercultural studies and theology to be exact.</p>
          <p>But I loved how computers worked. I love how they think. I love the GIGO principle (ya know, Garbage in -> Garbage Out). It means that crap software is that way because of what goes in, which also means if you can control what goes in, you might get something beautiful coming out. I love logic (formal and informal). I even taught logic to Middle Schoolers for a couple of years.</p>
          <p>After getting some wise counsel from friends, parents, my pastor, I decided to go all-in on coding.</p>
          <TertiaryHeading>Discovering My Powers&mdash;Bootcamp + Self-Study: Aug 2016&ndash;May 2017</TertiaryHeading>
          <QuartenaryHeading>Arch-Nemesis: Time & Money</QuartenaryHeading>
          <p>I won&rsquo;t go into too much detail here. I will publish a blog that highlights the rationale behind my choices. Here&rsquo;s what I accomplished in 8 months of intense training:</p>
          <ul>
            <li><p>Completed Khan Academy&rsquo;s Computer Science courses on <strong>HTML</strong>/<strong>CSS</strong>/<strong>JavaScript</strong>&mdash;the JavaScript courses were very helpful in that while they use the <strong>processing.js</strong> library, their teaching focuses upon <strong>Object-Oriented Programming</strong></p></li>
            <li><p>Completed FreeCodeCamp&rsquo;s <a href="https://www.freecodecamp.org/certification/wesleylhandy/legacy-front-end">Front End Certification</a> and most of their Back End Certification&mdash;particularly helpful was their section on algorithms and their project-focused curriculum (just try to beat <a href="https://wesleylhandy.github.io/tic-tac-toe/">my tic-tac-toe game</a>).</p></li>
            <li><p>Completed Wes Bos&rsquo; JavaScript30 course, as well as many other courses by others on Udemy and other venues on <strong>MySql</strong>, <strong>CSS Flexbox</strong>, <strong>CSS Grid</strong>, <strong>PHP</strong>, <strong>Python</strong>, and others</p></li>
            <li><p>Completed 3 Mongo University courses on <strong>MongoDB</strong></p></li>
            <li><p>Completed Rutgers Full-Stack Coding Bootcamp through Rutgers University&mdash;a 24-week intensive bootcamp teaching the MERN stack&mdash;<strong>MongoDB</strong>/<strong>MySql</strong> <strong>Express</strong> <strong>React</strong> <strong>Node</strong></p></li>
          </ul>
          <p>I started applying for positions in March of 2017, failed some interviews, which always helped me know what I didn&rsquo;t know. But in June of 2017 I received my first three offers back in my hometown of Virginia Beach, VA. So I took one and relocated.</p>
          <TertiaryHeading>Hero&rsquo;s Journey Begun&mdash;Professional Developer: Apr 2017&ndash;Present</TertiaryHeading>
          <QuartenaryHeading>Arch-Nemesis: IE11 & Imposter Syndrome</QuartenaryHeading>
          <p>The only way to conquer fear is to battle it head on. And my first couple of years, it has been easy to doubt myself, especially when all the other devs my age have almost 20 years of experience in the field. But I have learned a lot from them, adding my passion for new technology to their wisdom in learning broadly and pacing oneself. I have greatly benefited from working with legacy systems as well as stacks for which I received no training, seeing how strongly-typed, dependency-injected, and object-oriented languages and frameworks have strengths as well as weaknesses. This has helped me understand JavaScript development more deeply.</p>
          <QuartenaryHeading>JavaScript Tutor -> Senior JavaScript Tutor</QuartenaryHeading>
          <p>For eight months between April and November 2017, I was a <strong>JavaScript tutor</strong> with Trilogy Education Services for students in other bootcamps at major universities around the country. I was promoted to <strong>Senior JavaScript Tutor</strong> in Aug 2017 in light of my high quality reviews from students and my supervisor. I left this position that November to focus on other more lucrative side-work.</p>
          <QuartenaryHeading>Internet Developer I -> Internet Developer II</QuartenaryHeading>
          <p>Professionally, I have spent the last two years becoming an expert at <strong>CSS</strong> and <strong>JavaScript</strong> through my day to day job as a developer at The Christian Broadcasting Network in the Digital Media Group. I build landing pages and webforms for promoting products and marketing dontation campaigns. In this, I have learned quite a bit of <strong>C#</strong>/<strong>.Net</strong> as well as furthered my proficiency with <strong>PHP</strong>. I have built dozens of pages with hundreds of thousands of views. I have built forms in <strong>React</strong> that have handled hundreds of thousands of submissions. My favorite project thus far has been building a <strong>Wordpress</strong> plugin that enables a shortcode that loads a customizable <strong>React</strong> Form that connects to an internal company API. This will enable us to move our giving and product forms outside of our up-to-now dotNet environment to serve the multitude of CBN owned properties which can be branded uniquely. The admin portal is another <strong>React</strong> application that enables users to configure the <strong>JSON</strong> that will be sent to the front end form to configure both that form&rsquo;s style and options. The plugin proxies requests between the Portal, the WP-JSON API, with custom endpoints, custom WPDB tables, the Form on the page, and the CBN Giving API. It is true full-stack application. In August 2018, in recognition of my growth, skills, and contributions, I was promoted from <strong>Internet Developer I</strong> to <strong>Internet Developer II</strong>.</p>
          <QuartenaryHeading>Web Development Business Owner</QuartenaryHeading>
          <p>On the side, I have founded <strong>Creativ, LLC</strong> for building and developing sites and ecommerce sites for local small businesses. I am currently building an online store for a Medical Supply Company.</p>
          <QuartenaryHeading>Open Source Contributor</QuartenaryHeading>
          <p>I have also begun making <strong>Open Source</strong> contributions to <strong>Gatsby.js</strong>&mdash;the framework with which this site is built.</p>
          <TertiaryHeading>What Comes Next?</TertiaryHeading>
          <p>Great question! I want to continue to grow as a developer. I am always looking for new things to learn (even if they are legacy to most), but I really want to continue working in <strong>Gatsby</strong>, even if only within my side-business, but definitely more in <strong>React</strong>, <strong>MongoDB</strong>, and <strong>Node.js</strong>, if possible.</p>
          <Bio text="Contact Wesley L. Handy with any remote opportunities as a Full-Stack MongoDB/React/Node developer." />
          <LinkContainer>
            <Link to="/">Go back to the homepage</Link>
          </LinkContainer>
        </Layout>
      )
    }}
  />
)

export default AboutPage
